import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  AppBar,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
  useMediaQuery,
  useTheme
} from "@material-ui/core"
import {
  Announcement as BlogIcon,
  Close as CloseIcon,
  ExitToApp as SignInIcon,
  Menu as MenuIcon,
  PersonAdd as JoinUsIcon,
  SupervisedUserCircle as LeadershipIcon,
  QuestionAnswer as FAQIcon
} from "@material-ui/icons"
import {
  BLOG,
  FAQ,
  JOIN,
  ABOUT_US,
  ROOT,
  SIGN_IN,
  SCHEDULE,
  STORE
} from "../urls"

const TOOLBAR_HEIGHT = 72
const DRAWER_WIDTH = 240
const BACKGROUND_IMAGE = "linear-gradient(90deg,#141da2,#9b5cf6)"

function Header () {
  const [showDrawer, setShowDrawer] = useState(false)

  const theme = useTheme()
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"))

  const rootStyle = {}
  const appBarStyle = {}
  appBarStyle.position = "fixed"
  appBarStyle.transform = `translateY(${TOOLBAR_HEIGHT}px)`
  if (!isSmallDevice) {
    appBarStyle.transition = "transform 500ms ease, background 500ms ease"
  }
  appBarStyle.backgroundImage = BACKGROUND_IMAGE
  appBarStyle.top = -TOOLBAR_HEIGHT
  rootStyle.height = TOOLBAR_HEIGHT

  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      ...rootStyle
    },
    appBar: {
      boxShadow: "none",
      ...appBarStyle
    },
    toolbar: {
      height: TOOLBAR_HEIGHT,
      margin: isSmallDevice ? 0 : "0 3em"
    },
    menuItem: {
      font: "500 12px/80px \"Roboto\", sans-serif",
      textTransform: "uppercase",
      color: "white"
    },
    drawer: {
      width: DRAWER_WIDTH,
      flexShrink: 0
    },
    drawerPaper: {
      width: DRAWER_WIDTH
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: "0 8px",
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
      backgroundColor: "#9b5cf6",
      height: TOOLBAR_HEIGHT
    },
    drawerHeaderCloseIcon: {
      color: "white"
    },
    drawerList: {
      paddingTop: 20,
      paddingLeft: 10
    },
    drawerLink: {
      color: theme.palette.text.primary
    }
  }))
  const classes = useStyles()

  const handleOpenDrawer = () => {
    setShowDrawer(true)
  }

  const handleDrawerClose = ({ type, key }) => {
    if (type === "keydown" && (key === "Tab" || key === "Shift")) {
      return
    }
    setShowDrawer(false)
  }

  return (
    <div className={classes.root}>
      <AppBar position="absolute" className={classes.appBar}>
        {isSmallDevice && (
          <SwipeableDrawer
            className={classes.drawer}
            anchor="right"
            open={showDrawer}
            onOpen={handleOpenDrawer}
            classes={{
              paper: classes.drawerPaper
            }}
            onClose={handleDrawerClose}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose}>
                <CloseIcon className={classes.drawerHeaderCloseIcon} />
              </IconButton>
            </div>
            <Divider />
            <List className={classes.drawerList}>
              <a href={SIGN_IN} onClick={handleDrawerClose}>
                <ListItem button>
                  <ListItemIcon>
                    <SignInIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Sign in"
                    className={classes.drawerLink}
                  />
                </ListItem>
              </a>
              <a href={JOIN} onClick={handleDrawerClose}>
                <ListItem button>
                  <ListItemIcon>
                    <JoinUsIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Join us"
                    className={classes.drawerLink}
                  />
                </ListItem>
              </a>
              <a href={SCHEDULE} onClick={handleDrawerClose}>
                <ListItem button>
                  <ListItemIcon>
                    <LeadershipIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Schedule"
                    className={classes.drawerLink}
                  />
                </ListItem>
              </a>

              <a href={ABOUT_US} onClick={handleDrawerClose}>
                <ListItem button>
                  <ListItemIcon>
                    <LeadershipIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="About Us"
                    className={classes.drawerLink}
                  />
                </ListItem>
              </a>

              <a href={FAQ} onClick={handleDrawerClose}>
                <ListItem button>
                  <ListItemIcon>
                    <FAQIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="FAQ" className={classes.drawerLink} />
                </ListItem>
              </a>

              <a href={BLOG} onClick={handleDrawerClose}>
                <ListItem button>
                  <ListItemIcon>
                    <BlogIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Latest news"
                    className={classes.drawerLink}
                  />
                </ListItem>
              </a>

              <a href={STORE} onClick={handleDrawerClose}>
                <ListItem button>
                  <ListItemIcon>
                    <BlogIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Store"
                    className={classes.drawerLink}
                  />
                </ListItem>
              </a>
            </List>
          </SwipeableDrawer>
        )}

        <Toolbar className={classes.toolbar}>
          <div className="d-flex w-100 align-items-center">
            <a href={ROOT}>
              <img
                src="img/logo.png"
                alt=""
                style={{ verticalAlign: "initial" }}
              />
            </a>

            <div style={{ flexGrow: 1 }} />

            <div
              className="d-flex justify-content-around"
              style={{ flexGrow: 3 }}
            >
              {!isSmallDevice && (
                <>
                  <a href={SCHEDULE} className={classes.menuItem}>
                    <div className="text-white d-flex align-items-center">
                      <span className="mx-2">Schedule</span>
                    </div>
                  </a>
                  <a href={ABOUT_US} className={classes.menuItem}>
                    <div className="text-white d-flex align-items-center">
                      <span className="mx-2">About Us</span>
                    </div>
                  </a>
                  <a href={FAQ} className={classes.menuItem}>
                    <div className="text-white d-flex align-items-center">
                      <span className="mx-2">FAQ</span>
                    </div>
                  </a>
                  <a href={BLOG} className={classes.menuItem}>
                    <div className="text-white d-flex align-items-center">
                      <span className="mx-2">Latest news</span>
                    </div>
                  </a>
                  <a href={STORE} className={classes.menuItem}>
                    <div className='text-white d-flex align-items-center'>
                      <span className='mx-2'>Store</span>
                    </div>
                  </a>
                </>
              )}
            </div>

            <div style={{ flexGrow: 1.5 }} />
          </div>
          <div
            style={{ width: "20em" }}
            className="d-flex flex-row-reverse justify-content-between"
          >
            {isSmallDevice && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="Menu"
                onClick={() => setShowDrawer(!showDrawer)}
              >
                <MenuIcon fontSize="large" />
              </IconButton>
            )}
            {!isSmallDevice && (
              <a href={JOIN}>
                <Button variant="contained" color="primary" className="ml-5">
                  Join Us
                </Button>
              </a>
            )}
            {!isSmallDevice && (
              <a href={SIGN_IN} className="signin-btn text-white">
                <Button className="text-white">Sign in</Button>
              </a>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Header
