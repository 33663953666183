import React from "react"
import muiTheme from "../MuiTheme"
import { MuiThemeProvider } from "@material-ui/core/styles"

import { rhythm } from "../utils/typography"
import Footer from "./Footer"
import Header from "./Header"

class Layout extends React.Component {
  render() {
    const { children } = this.props
    return (
      <>
        <MuiThemeProvider theme={muiTheme}>
          <div
            style={{
              marginLeft: `auto`,
              marginRight: `auto`,
              maxWidth: rhythm(28),
              padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`
            }}
          >
            <Header />
            <main>{children}</main>
          </div>
          <Footer />
        </MuiThemeProvider>
      </>
    )
  }
}

export default Layout
