/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

function SEO({ description, lang, meta, title, postData, pageContext }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  description = description || site.siteMetadata.description

  const url =
    pageContext && pageContext.slug
      ? `${site.siteMetadata.siteUrl}${pageContext.slug}`
      : site.siteMetadata.siteUrl

  const image =
    postData && postData.frontmatter.featuredImage
      ? `${site.siteMetadata.siteUrl}${postData.frontmatter.featuredImage.childImageSharp.sizes.src}`
      : `${site.siteMetadata.siteUrl}/belmont-runners-icon.png`

  const author = postData
    ? postData.frontmatter.author
    : site.siteMetadata.author

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          property: `og:url`,
          content: url
        },
        {
          property: `og:title`,
          content: title
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `description`,
          content: description
        },
        {
          property: `og:description`,
          content: description
        },
        {
          name: `twitter:description`,
          content: description
        },
        {
          name: `twitter:creator`,
          content: author
        },
        {
          property: `og:image`,
          content: image
        },
        {
          property: `og:type`,
          content: `blog`
        },
        {
          name: `twitter:card`,
          content: `summary`
        }
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  postData: PropTypes.object,
  pageContext: PropTypes.object
}

export default SEO
